<!-- =========================================================================================
    File Name: Listusers.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="vx-card bg-dark">
    <h4 class="pl-8 pt-6" v-if="title">{{ title }}</h4>

    <div class="p-8">
      <div class="flex flex-wrap justify-between">
        <div class="flex">
          <vs-dropdown class="cursor-pointer" vs-trigger-click>
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium mr-2"
            >
              <span>
                {{ currentPage * paginationPageSize - (paginationPageSize - 1) }} -
                {{ userData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : userData.length }} of
                {{ totalUsers }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(10);
                  loadProductBuffer();
                "
              >
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(20);
                  loadProductBuffer();
                "
              >
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(50);
                  loadProductBuffer();
                "
              >
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(100);
                  loadProductBuffer();
                "
              >
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item
                @click="
                  gridApi.paginationSetPageSize(150);
                  loadProductBuffer();
                "
              >
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <vs-button color="primary" class="ml-2 round" type="gradient" @click="selectUserCreateTypePopup = !selectUserCreateTypePopup"
            >Create New User
          </vs-button>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex items-center">
          <vs-input
            @input="updateSearchQuery"
            class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            placeholder="Search..."
            v-model="searchQuery"
          />
          <vs-button color="primary" icon-pack="feather" icon="icon-filter" class="" type="gradient" @click="filterPopup = !filterPopup"
            >Open Filter
          </vs-button>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        :animateRows="true"
        :columnDefs="columnDefs"
        :components="components"
        :defaultColDef="defaultColDef"
        :enableRtl="$vs.rtl"
        :floatingFilter="true"
        :gridOptions="gridOptions"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowData="userData"
        :suppressPaginationPanel="true"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        colResizeDefault="shift"
        ref="agGridTable"
      >
      </ag-grid-vue>

      <vs-pagination :total="totalPages" v-model="currentPage" />

      <vs-popup :active.sync="selectUserCreateTypePopup" title="Select Action">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2 hover:text-primary cursor-pointer" @click="uploadUsers">
            <div class="border border-solid m-3 p-6 rounded">
              <div class="flex items-center justify-center">
                <feather-icon icon="UploadIcon" svgClasses="h-16 w-16" />
              </div>
              <h2 class="text-center mt-4 text-white">Upload Multiple<br />Users</h2>
            </div>
          </div>
          <div
            class="vx-col w-1/2 hover:text-primary cursor-pointer"
            @click="
              createNewUserActive = true;
              selectUserCreateTypePopup = false;
            "
          >
            <div class="border border-solid m-3 p-6 rounded">
              <div class="flex items-center justify-center">
                <feather-icon icon="UserPlusIcon" svgClasses="h-16 w-16" />
              </div>
              <h2 class="text-center mt-4 text-white">Create Single<br />User</h2>
            </div>
          </div>
        </div>
      </vs-popup>
      <vs-popup :active.sync="createNewUserActive" title="Create New User" id="RegUserPopup">
        <vs-input v-model="createNewUserValues.firstName" class="mb-8 w-full" label-placeholder="First Name" />
        <vs-input v-model="createNewUserValues.lastName" class="mb-8 w-full" label-placeholder="Last Name" />
        <vs-input
          v-model="createNewUserValues.email"
          class="mb-8 w-full"
          name="email"
          v-validate="'required|min:3'"
          label-placeholder="Email"
        />
        <vs-input v-model="createNewUserValues.password" class="mb-6 w-full" label-placeholder="Password" />

        <vs-button color="success" type="filled" @click="createAccount">Create Account</vs-button>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import TableName from './components/TableName.vue';
import TableActions from './components/TableActions.vue';
import TableID from './components/TableID';
import TableEmail from './components/TableEmail';
import { AgGridVue } from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import vSelect from 'vue-select';

export default {
  components: {
    AgGridVue,
    vSelect,
    TableName,
    TableActions,
    TableID,
    TableEmail,
  },
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      showArchived: false,

      userData: [],
      totalUsers: 0,

      searchQuery: '',

      selectUserCreateTypePopup: false,

      createNewUserActive: false,
      createNewUserValues: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },

      filterPopup: false,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        valueFormatter: (params) => {
          if (typeof params.value === 'string') {
            const value = params.value;
            return value.charAt(0).toUpperCase() + value.slice(1);
          }

          return params;
        },
      },

      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: true,
          width: 250,
          cellRendererFramework: 'TableID',
        },
        {
          headerName: 'Name',
          field: 'fullName',
          filter: true,
          width: 400,
          cellRendererFramework: 'TableName',
        },
        {
          headerName: 'Email',
          field: 'email',
          filter: true,
          cellRendererFramework: 'TableEmail',
        },
        // {
        //   headerName: 'Groups',
        //   field: 'groups',
        //   filter: true,
        // },
        {
          headerName: 'Actions',
          width: 150,
          sortable: false,
          cellRendererFramework: 'TableActions',
          cellRendererParams: {
            loadProductBuffer: this.loadProductBuffer.bind(this),
          },
        },
      ],

      // Cell Renderer Components
      components: {
        TableName,
        TableActions,
        TableID,
        TableEmail,
      },
      totalPages: 0,
    };
  },
  watch: {
    'createNewUserValues.firstName'() {
      this.createNewUserValues.password = 'student12345';
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
        this.loadProductBuffer(val);
      },
    },
  },
  methods: {
    uploadUsers() {
      this.selectUserCreateTypePopup = false;
      setTimeout(() => {
        this.$router.push('/a/users/upload');
      }, 10);
    },
    createAccount() {
      const pl = {
        email: this.createNewUserValues.email,
        password: this.createNewUserValues.password,
        firstName: this.createNewUserValues.firstName,
        lastName: this.createNewUserValues.lastName,
      };

      this.$http
        .post('user', pl)
        .then((response) => {
          this.$vs.loading.close();
          const userId = response.data.id;
          if (response.status === 200) {
            this.createNewUserActive = false;
            this.$router.push(`/a/users/${userId}`);
            return this.$vs.notify({
              color: 'success',
              title: 'Account Created',
              text: 'You have successfully created a accounts',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right',
            });
          }
        })
        .catch((exception) => {
          console.dir(exception);
          this.$vs.loading.close();
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to create Account',
            text: 'Could not create the student account',
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right',
          });
        });
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== 'all') {
        modelObj = { type: 'equals', filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    updateSearchQuery() {
      this.currentPage = 0;
    },
    loadProductBuffer(page = 0) {
      if (page === 0) {
        page = this.currentPage;
      }
      this.$http
        .get(`user?pageSize=${this.paginationPageSize}&page=${page}&search=${this.searchQuery}`)
        .then((response) => {
          if (response.data) {
            this.userData = response.data.rows;
            this.totalPages = response.data.totalPages;
            this.totalUsers = response.data.total;
          }

          this.userData = this.userData.map((user) => {
            user.photoURL = 'https://portal-cdn.rapid.education/profile-pictures/default.jpeg';
            return user;
          });

          this.gridApi.sizeColumnsToFit();
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while loading the user course-classroom-list';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to load user course-classroom-list',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container');
      header.style.left = `-${String(Number(header.style.transform.slice(11, -3)) + 9)}px`;
    }
  },
};
</script>
<style lang="scss">
.theme-dark {
  #UserFilterPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
  #RegUserPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
