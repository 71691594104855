<template>
  <div class="flex items-center">
    <a class="text-inherit hover:text-primary" @click="copyText(params.value)">{{ params.value }}</a>
  </div>
</template>

<script>
export default {
  methods: {
    copyText (x) {
      const thisIns = this
      this.$copyText(x).then(function () {
        thisIns.$vs.notify({
          title: 'Success',
          text: 'Email Copied to Clipboard',
          color: 'success',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-clipboard'
        })
      })
    }
  }
}

</script>
