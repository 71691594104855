<template>
  <div>
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
    <feather-icon @click="viewRecord" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />

    <!--- <feather-icon @click="addStudentSearchActive = true" icon="UserPlusIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"/>
      <vs-popup :active.sync="addStudentSearchActive" title="Search for User by Name">

        <vs-table :data="addStudentSearchResults" :sst="true" max-items="5" search
                  @search="addStudentCourseSearch">
          <template slot="header">
            <h3>Courses</h3>
          </template>

          <template slot="thead">
            <vs-th>Id</vs-th>
            <vs-th>Name</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr v-for="(attach) in data" :key="attach.id">

              <vs-td :data="attach.id">
                {{ attach.id }}
              </vs-td>

              <vs-td :data="`${attach.name}`">
                <div class="flex align-middle items-center">
                  <img :src="attach.thumbnail" class="rounded mr-3" width="40px">
                  {{ `${attach.name}` }}
                </div>
              </vs-td>

              <vs-td>
                <vs-button color="primary" size="small" type="border" @click="addStudentToCourseOptions(attach)">
                  Assign
                </vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>
      <vs-popup :active.sync="addStudentAssignActive" :title="`Assign Course To User`">

        <vx-card class="my-4">
          <div class="vx-row ">
            <div class="vx-col w-1/5 hidden md:flex items-center align-middle">
              <img
                :src="`https://portal-cdn.rapid.education/profile-pictures/${params.data.avatar !== null ? params.data.avatar : 'default'}.jpeg`"
                class="responsive rounded" alt="User Img">
            </div>
            <div class="vx-col w-5/5 md:w-4/5 flex items-center align-middle ">
              <div class="my-2">
                <h5 class="mb-2">{{ params.data.firstName }} {{ params.data.lastName }}</h5>
                <h5 class="mb-2">{{ params.data.email }}</h5>
              </div>
            </div>
          </div>
        </vx-card>

        <vx-card class="my-4">
          <div class="vx-row ">
            <div class="vx-col w-1/5 hidden md:flex items-center align-middle">
              <img :src="addStudentAssignCourse.thumbnail"
                   class="responsive rounded">
            </div>
            <div class="vx-col w-5/5 md:w-4/5 flex items-center align-middle ">
              <div class="my-2">
                <h5 class="mb-2">{{ addStudentAssignCourse.name }}</h5>
                <h5 class="mb-2">{{ addStudentAssignCourse.description }}</h5>
              </div>
            </div>
          </div>
        </vx-card>

        <div class="my-4">
          <h6>Choose Subscription Duration</h6>
          <ul class="flex mt-3">
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" vs-value="1" class="mr-4">1 Month</vs-radio>
            </li>
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" vs-value="3" class="mr-4">3 Months</vs-radio>
            </li>
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" vs-value="6" class="mr-4">6 Months</vs-radio>
            </li>
            <li>
              <vs-radio v-model="addStudentAssignCourseDuration" vs-value="12" class="mr-4">1 Year</vs-radio>
            </li>
          </ul>
        </div>
        <p class="text-danger font-bold" v-if="addStudentAssignCourseDuration === null">You Must Choose A
          Subscription
          Duration</p>

        <vs-button color="primary" type="filled"
                   @click="addStudentToCourseEnroll(addStudentAssignCourse, addStudentAssignCourseDuration)"
                   v-if="addStudentAssignCourseDuration">Assign Student
        </vs-button>

      </vs-popup>
      <vs-popup :active.sync="viewUserSubscriptionsActive" title="View User Subscriptions">
        <vx-card class="my-4" v-for="item in viewUserSubscriptionsResults">
          {{item}}
          <div class="vx-row ">
            <div class="vx-col w-1/5 hidden md:flex items-center align-middle">
              <img
                   class="responsive rounded">
            </div>
            <div class="vx-col w-5/5 md:w-4/5 flex items-center align-middle ">
              <div class="my-2">
                <h5 class="mb-2"></h5>
                <h5 class="mb-2"></h5>
              </div>
            </div>
          </div>
        </vx-card>
      </vs-popup> --->
  </div>
</template>

<script>
export default {
  /* data () {
    return {
      addStudentSearchActive: false,
      addStudentAssignActive: false,
      addStudentSearchResults: [],
      addStudentAssignCourse: {},
      addStudentAssignCourseDuration: null,
      subTableKey: 0,

      viewUserSubscriptionsActive: false,
      viewUserSubscriptionsResults: []

    }
  }, */
  methods: {
    editRecord() {
      this.$router.push(`/a/users/${this.params.data.id}/edit`).catch(() => {});
    },
    viewRecord() {
      this.$router.push(`/a/users/${this.params.data.id}`).catch(() => {});
    },
    showError(exception) {
      this.$vs.loading.close();

      let error = 'An unknown error occurred while archive this courses';
      if (exception.response) {
        error = exception.response.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive courses',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
    /* addStudentCourseSearch (term) {
      if (!term) return
      this.$http.get(`course?search=${term}&pageSize=5`)
        .then(response => {
          if (response.data) {
            this.addStudentSearchResults = response.data.data
          }
        })
    },
    addStudentToCourseOptions (id) {

      this.addStudentSearchActive = false
      this.addStudentAssignActive = true
      this.addStudentAssignCourse = id

    },
    addStudentToCourseEnroll (courses, duration) {

      this.$vs.loading()
      const date = new Date()


      if (!duration) {
        this.$vs.notify({
          color: 'danger',
          title: 'Failed to create Subscription',
          text: 'Please provide subscription duration.',
          icon: 'feather',
          iconPack: 'icon-x-circle',
          position: 'top-right'
        })
        return this.$vs.loading.close()
      }

      const pl = {
        user_id: this.params.data.id,
        course_id: courses.id,
        status: 'active',
        ending_date: new Date(date.setMonth(date.getMonth() + duration)).getTime()
      }

      this.$http.post('subscriptions/create', pl)
        .then(response => {
          this.$vs.loading.close()

          if (response.status === 200) {
            return this.$vs.notify({
              color: 'success',
              title: 'Subscription Created',
              text: 'You have successfully created a subscription',
              icon: 'feather',
              iconPack: 'icon-check-circle',
              position: 'top-right'
            })
          }

        }).catch(exception => {
          this.$vs.loading.close()
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to create Subscription',
            text: exception.response.data.error.description,
            icon: 'feather',
            iconPack: 'icon-x-circle',
            position: 'top-right'
          })
        })

      this.subTableKey++
      this.addStudentAssignActive = false
      this.addStudentSearchActive = true

    },
    loadUserSubscriptions () {
      this.$http.get(`subscriptions?cancelled=false&user=${this.params.data.id}&sort[0][field]=id&sort[0][value]=dsc`)
        .then(response => {
          if (response.data.data) {
            this.viewUserSubscriptionsResults = response.data.data
          }
        })
        .catch(() => {
          return this.$vs.notify({
            title: 'Failed to load subscription course-classroom-list',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    } */
  },
};
</script>
