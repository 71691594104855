<template>
  <div class="flex items-center">
    <vs-avatar :src="params.data.photoURL" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" />
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary text-lg font-bold">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  computed: {
    url() {
      return `/a/users/${this.params.data.id}`;
    },
  },
};
</script>
